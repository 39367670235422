<template>
  <VCard>
    <VCardTitle>
      Поиск пользователя по Login
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.login"
                label="Логин"
                @input="$emit('update:login', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.type"
                :items="types"
                item-text="title"
                item-value="value"
                label="Тип"
                @input="$emit('update:type', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Найти
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_ACCOUNT, TYPES_LIST } from './common';

export default {
  name: 'SearchLoginForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_ACCOUNT }),
    },

    types: {
      type: Array,
      default: () => TYPES_LIST,
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
