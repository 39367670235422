<template>
  <TTDataTable
    class="user-table"
    :headers="headers"
    :items="users"
    disable-pagination
    hide-default-footer
  >
    <template #item.id="{ item }">
      <RouterLink :to="{ name : Names.R_AUTHENTICATION_USER, params : { userId : item.id } }">
        {{ item.id }}
      </RouterLink>
    </template>
  </TTDataTable>
</template>

<script>
export default {
  name: 'SearchUsersTable',

  inject: ['Names'],

  props: {
    result: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: true,
        },
        {
          text: this.$t('user_search.full_name'),
          value: 'fullName',
          sortable: true,
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('user_search.phone'),
          value: 'phone',
          sortable: true,
        },
      ],
    };
  },

  computed: {
    users() {
      return this.result.map(
        ({
          email, id, phone, lastName, firstName, middleName,
        }) => ({
          email, id, phone, fullName: [lastName, firstName, middleName].filter((n) => n).join(' '),
        }),
      );
    },
  },
};
</script>
