<template>
  <VRow align="center">
    <VCol>
      <VCard>
        <VCardTitle>
          Поиск пользователя по ФИО
        </VCardTitle>
        <VCardText>
          <TTTextField
            :value="value"
            :loading="loading"
            large
            :messages="message"
            :placeholder="$t('user_search.placeholder')"
            @input="$emit('input', $event)"
            @blur="handlerSubmit"
            @keyup.enter="handlerSubmit"
          >
            <template #append>
              <TTBtn
                width="24"
                icon
                color="tt-light-mono-100"
                @click.stop="handlerSubmit"
              >
                <VIcon>
                  fal fa-search
                </VIcon>
              </TTBtn>
            </template>
          </TTTextField>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>

export default {
  name: 'SearchUsersForm',

  props: {
    value: {
      type: String,
      default: '',
    },
    result: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    message() {
      if (this.resultCount !== -1) { return this.$t('user_search.search_found', { count: this.resultCount }); }
      return null;
    },
    resultCount() {
      if (this.value && this.result) return this.result.length;
      return -1;
    },
  },

  methods: {
    handlerSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
