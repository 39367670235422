<template>
  <TTView>
    <VRow>
      <VCol>
        <VTabs
          v-model="tab"
          class="elevation-1"
        >
          <VTab @click="handleReset">
            Login
          </VTab>

          <VTab @click="handleReset">
            UUID
          </VTab>

          <VTab @click="handleReset">
            {{ $t('user_search.full_name') }}
          </VTab>

          <VTabsItems :value="tab">
            <VTabItem>
              <SearchLoginForm
                class="elevation-0"
                :entity="account"
                @update:login="account.login = $event"
                @update:type="account.type = $event"
                @cancel="handleCancel"
                @submit="handleGetUserIDByLogin"
              />
            </VTabItem>

            <VTabItem>
              <SearchUUIDForm
                class="elevation-0"
                :entity="account"
                @update:id="account.id = $event"
                @cancel="handleCancel"
                @submit="handleGetUser"
              />
            </VTabItem>

            <VTabItem>
              <SearchUserForm
                v-model="searchUser.text"
                :result="searchUser.result"
                :loading="searchUser.loading"
                @submit="handlerSearchUser"
              />
            </VTabItem>
          </VTabsItems>
        </VTabs>
      </VCol>
    </VRow>

    <VRow v-if="uuid && uuid !== -1">
      <VCol>
        <VCard>
          <VCardTitle>
            Пользователь найден
          </VCardTitle>

          <VCardText>
            <RouterLink :to="{ name : Names.R_AUTHENTICATION_USER, params : { userId : uuid } }">
              {{ uuid }}
            </RouterLink>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow v-else-if="uuid === -1">
      <VCol>
        <VCard>
          <VCardTitle>
            Пользователь не найден
          </VCardTitle>

          <VCardText>
            {{ account }}
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow v-else-if="searchUser.result && searchUser.result.length">
      <VCol>
        <VCard>
          <SearchUsersTable :result="searchUser.result" />
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_ACCOUNT } from '../../components/authentication/common';
import SearchUUIDForm from '../../components/authentication/SearchUUIDForm.vue';
import SearchLoginForm from '../../components/authentication/SearchLoginForm.vue';
import SearchUserForm from '../../components/authentication/SearchUsersForm.vue';
import SearchUsersTable from '../../components/authentication/SearchUsersTable.vue';

export default {
  name: 'UsersSearch',

  components: {
    SearchUsersTable,
    SearchUserForm,
    SearchUUIDForm,
    SearchLoginForm,
  },

  inject: ['Names'],

  data() {
    return {
      loading: false,
      tab: 0,
      account: { ...DEFAULT_ACCOUNT },
      uuid: '',
      searchUser: {
        text: null,
        result: null,
        loading: null,
        prev: null,
      },
    };
  },

  computed: {
    query() {
      return this.$route.query.q;
    },
  },

  async created() {
    if (this.query) {
      this.tab = 2;
      this.searchUser.text = this.query;
      await this.handlerSearchUser();
    }
  },

  methods: {
    async handlerSearchUser() {
      if (!this.searchUser.text) this.searchUser.result = null;
      else if (this.searchUser.text && this.searchUser.prev !== this.searchUser.text) {
        this.searchUser.loading = true;
        try {
          this.searchUser.prev = this.searchUser.text;
          const { users } = await this.$di.api.Index.searchUser(this.searchUser.text);
          this.searchUser.result = users;
          if (this.query !== this.searchUser.text) {
            this.$router.push({ query: { q: this.searchUser.text } });
          }
        } catch (err) {
          this.$di.notify.errorHandler(err);
        } finally {
          this.searchUser.loading = false;
        }
      }
    },

    handleReset() {
      this.account = { ...DEFAULT_ACCOUNT };
      this.uuid = '';
      this.searchUser = {
        text: null,
        result: null,
        loading: null,
        prev: null,
      };
      if (Object.keys(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },

    async handleGetUser() {
      try {
        this.loading = true;
        const { id } = this.account;
        const data = { id };
        await this.$di.api.Authentication.getUser(data);

        this.uuid = id;
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.uuid = -1;
      } finally {
        this.loading = false;
      }
    },

    async handleGetUserIDByLogin() {
      try {
        this.loading = true;
        const { login, type } = this.account;
        const data = { login, type };
        const { id } = await this.$di.api.Authentication.getUserIDByLogin(data);

        this.uuid = id;
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.uuid = -1;
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
