<template>
  <VCard>
    <VCardTitle>
      Поиск пользователя по UUID
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.id"
                label="UUID"
                @input="$emit('update:id', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Найти
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_ACCOUNT } from './common';

export default {
  name: 'SearchUUIDForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_ACCOUNT }),
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
